import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"
import Layout from "../components/common/layout"
import Seo from "../components/common/seo"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { Helmet } from "react-helmet"
import { INLINES } from "@contentful/rich-text-types"
import { Link } from "gatsby"

const options = {
    renderNode: {
      [INLINES.HYPERLINK]: node => {
        if (node.data.uri.includes("youtube.com/embed")) {
          return (
            <iframe
              src={node.data.uri}
              allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
              frameBorder="0"
              allowFullScreen
              style={{ width: "100%", height: "400px" }}
            ></iframe>
          )
        } else if (node.data.uri.includes("https://"))  {
          return (
            <a href={node.data.uri} target="_blank" rel="noreferrer">
              {node.content[0].value}
            </a>
          )
        } else  {
          return (
            <Link to={node.data.uri}>
              {node.content[0].value}
            </Link>
          )
        }
      },
    },
    }

const ProvincialGuidelines = ({ data }) => {
  const {
    title,
    description,
    item1,
    content1,
    item2,
    content2,
    item3,
    content3,
    item4,
    content4,
    item5,
    content5,
    item6,
    content6,
  } = data.contentfulProvincialGuidelines

  const [idx, setIdx] = useState(0)
  useEffect(() => {
    if (window.location.href.indexOf("BCAA") > -1) {
      document.querySelector('.resources_sidebar_content .res_common_sidebar_div ul.tabs li:nth-child(2)').click();
    } else if (window.location.href.indexOf("ManitobaAA") > -1) {
      document.querySelector('.resources_sidebar_content .res_common_sidebar_div ul.tabs li:nth-child(3)').click();
    } else if (window.location.href.indexOf("NewBrunswickAA") > -1) {
      document.querySelector('.resources_sidebar_content .res_common_sidebar_div ul.tabs li:nth-child(4)').click();
    } else if (window.location.href.indexOf("NewfoundlandAA") > -1) {
      document.querySelector('.resources_sidebar_content .res_common_sidebar_div ul.tabs li:nth-child(5)').click();
    } else if (window.location.href.indexOf("NorthWestTerritoriesAA") > -1) {
      document.querySelector('.resources_sidebar_content .res_common_sidebar_div ul.tabs li:nth-child(6)').click();
    } else if (window.location.href.indexOf("NovaScotiaAA") > -1) {
      document.querySelector('.resources_sidebar_content .res_common_sidebar_div ul.tabs li:nth-child(7)').click();
    } else if (window.location.href.indexOf("NunavutAA") > -1) {
      document.querySelector('.resources_sidebar_content .res_common_sidebar_div ul.tabs li:nth-child(8)').click();
    } else if (window.location.href.indexOf("OntarioAA") > -1) {
      document.querySelector('.resources_sidebar_content .res_common_sidebar_div ul.tabs li:nth-child(9)').click();
    } else if (window.location.href.indexOf("PEIAA") > -1) {
      document.querySelector('.resources_sidebar_content .res_common_sidebar_div ul.tabs li:nth-child(10)').click();
    } else if (window.location.href.indexOf("QuebecAA") > -1) {
      document.querySelector('.resources_sidebar_content .res_common_sidebar_div ul.tabs li:nth-child(11)').click();
    } else if (window.location.href.indexOf("SaskatchewanAA") > -1) {
      document.querySelector('.resources_sidebar_content .res_common_sidebar_div ul.tabs li:nth-child(12)').click();
    } else if (window.location.href.indexOf("YukonAA") > -1) {
      document.querySelector('.resources_sidebar_content .res_common_sidebar_div ul.tabs li:nth-child(13)').click();
    } else if (window.location.href.indexOf("AlbertaTA") > -1) {
      document.querySelector('.resources_sidebar_content>div:nth-child(2)  li:nth-child(1)').click();
    } else if (window.location.href.indexOf("BCAATA") > -1) {
      document.querySelector('.resources_sidebar_content>div:nth-child(2)  li:nth-child(2)').click();
    } else if (window.location.href.indexOf("ManitobaTA") > -1) {
      document.querySelector('.resources_sidebar_content>div:nth-child(2)  li:nth-child(3)').click();
    } else if (window.location.href.indexOf("NewBrunswickTA") > -1) {
      document.querySelector('.resources_sidebar_content>div:nth-child(2)  li:nth-child(4)').click();
    } else if (window.location.href.indexOf("NewfoundlandTA") > -1) {
      document.querySelector('.resources_sidebar_content>div:nth-child(2)  li:nth-child(5)').click();
    } else if (window.location.href.indexOf("NorthWestTerritoriesTA") > -1) {
      document.querySelector('.resources_sidebar_content>div:nth-child(2)  li:nth-child(6)').click();
    } else if (window.location.href.indexOf("NovaScotiaTA") > -1) {
      document.querySelector('.resources_sidebar_content>div:nth-child(2)  li:nth-child(7)').click();
    } else if (window.location.href.indexOf("NunavutTA") > -1) {
      document.querySelector('.resources_sidebar_content>div:nth-child(2)  li:nth-child(8)').click();
    } else if (window.location.href.indexOf("OntarioTA") > -1) {
      document.querySelector('.resources_sidebar_content>div:nth-child(2)  li:nth-child(9)').click();
    } else if (window.location.href.indexOf("PEITA") > -1) {
      document.querySelector('.resources_sidebar_content>div:nth-child(2)  li:nth-child(10)').click();
    } else if (window.location.href.indexOf("QuebecTA") > -1) {
      document.querySelector('.resources_sidebar_content>div:nth-child(2)  li:nth-child(11)').click();
    } else if (window.location.href.indexOf("SaskatchewanTA") > -1) {
      document.querySelector('.resources_sidebar_content>div:nth-child(2)  li:nth-child(12)').click();
    } else if (window.location.href.indexOf("YukonTA") > -1) {
      document.querySelector('.resources_sidebar_content>div:nth-child(2)  li:nth-child(13)').click();
    }

  });
  return (
    <div className="resourcesPage provincialGuidelines">
      <Layout>
        <Seo title="Resources" />
        <div className="main_content_wrapper">
          <div className="resources_main_section">
            <div className="container">
              <div className="measurement_heading_section">
                <div className="resources_info_title">
                  {/* <h1>{heading}</h1> */}
                </div>
                <div className="common_desc">
                  {/* <p>{description.description}</p> */}
                </div>
              </div>
              <div className="resources_block">
                <div className="resources_left_col">
                  <div
                    className="resources_sidebar_block sidebar_menu_main"
                    id="sidebar"
                  >
                    <div className="resources_sidebar_content sidebar_menu_main_content">
                      {/* <div>
                        <div className="res_common_sidebar_div">
                          <div className="res_common_title_box sidebar_menu_title">
                            <h3>{item5}</h3>
                          </div>
                          <div className="res_common_list_ul sidebar_menu_content">
                            <ul>
                              {content5.map((item, i) => (
                                <li
                                  className="tab-link"
                                  data-tab={`tab-${item.id}`}
                                  key={item.id}
                                  onClick={() => setIdx(item.id)}
                                >
                                  <a href="#0">{item.listItem}</a>
                                </li>
                              ))}
                            </ul>
                          </div>
                        </div>
                      </div> */}
                      {/* Wire this up in contentful please !  */}
                      {/* <div>
                        <div className="res_common_sidebar_div">
                          <div className="res_common_title_box sidebar_menu_title">
                            <h3>Category Sheets </h3>
                          </div>
                          <div className="res_common_list_ul sidebar_menu_content">
                            <ul>
                                <li
                                  className="tab-link"
                                >
                                  <a href="#0">2022 </a>
                                </li>
                            </ul>
                          </div>
                        </div>
                      </div> */}

                      <div>
                        <div className="res_common_sidebar_div">
                          <div className="res_common_title_box sidebar_menu_title sidebar_menu_open ">
                            <h3>{item1}</h3>
                          </div>
                          <div className="res_common_list_ul sidebar_menu_content res_tab_list">
                            <ul className="tabs">
                              {content1.map((item, i) => (
                                <li
                                  className="tab-link"
                                  data-tab={`tab-${item.id}`}
                                  key={item.id}
                                  onClick={() => setIdx(item.id)}
                                >
                                  <a href="#0">{item.listItem}</a>
                                </li>
                              ))}
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="res_common_sidebar_div">
                          <div className="res_common_title_box sidebar_menu_title">
                            <h3>{item2}</h3>
                          </div>
                          <div className="res_common_list_ul sidebar_menu_content">
                            <ul>
                              {content2.map((item, i) => (
                                <li
                                  className="tab-link"
                                  data-tab={`tab-${item.id}`}
                                  key={item.id}
                                  onClick={() => setIdx(item.id)}
                                >
                                  <a href="#0">{item.listItem}</a>
                                </li>
                              ))}
                              {/* <li>
                                <a href="#0">Impact of Media Fragmentation</a>
                              </li>
                              */}
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="res_common_sidebar_div">
                          <div className="res_common_title_box sidebar_menu_title">
                            <h3>{item4}</h3>
                          </div>
                          <div className="res_common_list_ul sidebar_menu_content">
                            <ul>
                              {content4.map((item, i) => (
                                <li
                                  className="tab-link"
                                  data-tab={`tab-${item.id}`}
                                  key={item.id}
                                  onClick={() => setIdx(item.id)}
                                >
                                  <a href="#0">{item.listItem}</a>
                                </li>
                              ))}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="resources_right_col cm_right_content measurement_right_col">
                  <div className="resources_info_content_block ">
                    <div className="resources_info_title mobile-none">
                      <h1>{title}</h1>
                    </div>
                    <div className="common_desc mobile-none">
                      <p>{description.description}</p>
                    </div>

                    <div className="tabs_data">
                      {content1.map((item, i) => {
                        return (
                          // i === idx && (
                          <div
                            className={`tab-content ${
                              item.id === idx && "active"
                            }`}
                            id={`tab-${item.id}`}
                            key={item.id}
                          >
                            <div className="download_study_here">
                              <div className="measurement_info_content_block">
                                <div className="commb_data_report">
                                  <p className="#0">{item.listItem}</p>
                                </div>
                                <div className="tab_content_wrapper">
                                  <div className="measurement_ooh_data measurement-richtext">
                                    {renderRichText(item.content)}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          // )
                        )
                      })}
                      {content2.map((item, i) => {
                        return (
                          // i === idx && (
                          <div
                            className={`tab-content ${
                              item.id === idx && "active"
                            }`}
                            id={`tab-${item.id}`}
                            key={item.id}
                          >
                            <div className="download_study_here">
                              <div className="measurement_info_content_block">
                                <div className="commb_data_report">
                                  <p className="#0">{item.listItem}</p>
                                </div>
                                <div className="tab_content_wrapper">
                                  <div className="measurement_ooh_data measurement-richtext">
                                    {renderRichText(item.content)}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          // )
                        )
                      })}
                      {content3.map((item, i) => {
                        return (
                          // i === idx && (
                          <div
                            className={`tab-content ${
                              item.id === idx && "active"
                            }`}
                            id={`tab-${item.id}`}
                            key={item.id}
                          >
                            <div className="download_study_here">
                              <div className="measurement_info_content_block">
                                <div className="commb_data_report">
                                  <p className="#0">{item.listItem}</p>
                                </div>
                                <div className="tab_content_wrapper">
                                  <div className="measurement_ooh_data measurement-richtext">
                                    {renderRichText(item.content)}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          // )
                        )
                      })}
                      {content4.map((item, i) => {
                        return (
                          // i === idx && (
                          <div
                            className={`tab-content ${
                              item.id === idx && "active"
                            }`}
                            id={`tab-${item.id}`}
                            key={item.id}
                          >
                            <div className="download_study_here">
                              <div className="measurement_info_content_block">
                                <div className="commb_data_report">
                                  <p className="#0">{item.listItem}</p>
                                </div>
                                <div className="tab_content_wrapper">
                                  <div className="measurement_ooh_data measurement-richtext">
                                    {renderRichText(item.content)}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          // )
                        )
                      })}
                      {content5.map((item, i) => {
                        return (
                          // i === idx && (
                          <div
                            className={`tab-content ${
                              item.id === idx && "active"
                            }`}
                            id={`tab-${item.id}`}
                            key={item.id}
                          >
                            <div className="download_study_here">
                              <div className="measurement_info_content_block">
                                <div className="commb_data_report">
                                  <p className="#0">{item.listItem}</p>
                                </div>
                                <div className="tab_content_wrapper">
                                  <div className="measurement_ooh_data measurement-richtext">
                                    {renderRichText(item.content)}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          // )
                        )
                      })}
                      {content6.map((item, i) => {
                        return (
                          // i === idx && (
                          <div
                            className={`tab-content ${
                              item.id === idx && "active"
                            }`}
                            id={`tab-${item.id}`}
                            key={item.id}
                          >
                            <div className="download_study_here">
                              <div className="measurement_info_content_block">
                                <div className="commb_data_report">
                                  <p className="#0">{item.listItem}</p>
                                </div>
                                <div className="tab_content_wrapper">
                                  <div className="measurement_ooh_data measurement-richtext">
                                    {renderRichText(item.content)}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          // )
                        )
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </div>
  )
}

export default ProvincialGuidelines

export const query = graphql`
  query ProvincialGuidelines($language: String) {
    contentfulProvincialGuidelines(node_locale: { eq: $language }) {
      title
      description {
        description
      }
      item1
      content1 {
        id
        listItem
        content {
          raw
        }
      }
      item2
      content2 {
        id
        listItem
        content {
          raw
        }
      }
      item3
      content3 {
        id
        listItem
        content {
          raw
        }
      }
      item4
      content4 {
        id
        listItem
        content {
          raw
        }
      }
      item5
      content5 {
        id
        listItem
        content {
          raw
        }
      }
      item6
      content6 {
        id
        listItem
        content {
          raw
        }
      }
    }
  }
`